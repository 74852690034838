body {
    margin: 0;
    padding: 0;
}

.banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* Ajout du dégradé */
.banner::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%; /* Ajuste la hauteur du dégradé selon tes besoins */
    background: linear-gradient(transparent, rgb(2 20 18)); /* Transparent vers noir */
    z-index: 1; /* S'assurer que le dégradé est au-dessus de l'image */
}

.banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; /* S'assurer que l'image est en dessous du texte et du dégradé */
}

.banner_texte {
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.subtitle{
    margin: 0 0 0 15px;
    font-size: 2vw;
}
@media screen and (max-width:950px) {
    .banner_texte {
        left: 35%;
    }
}
@media screen and (max-width:750px) {
    .banner_texte {
        left: 50%;
    }
    .banner_texte h1 {
        color: #bde9ffb8;
        font-size: 3rem !important;
    }
}

.banner_texte h1 {
    color: #bde9ffb8;
    font-size: 10rem;
}

.banner_menu {
    text-decoration: none;
    position: absolute;
    top: 41%;
    right: 5%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 2; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.banner_menu a{
    text-decoration: none;
}

.banner_menu_link p{
    font-size: 2rem;
}