body {
    margin: 0;
    padding: 0;
}

.banner {
    position: relative;
    width: 100vw; 
    height: 100vh; 
    overflow: hidden; 
}

.banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.banner_texte h1 {
    color: #bde9ffb8;
    font-size: 20vw; 
    margin: 0;
}
