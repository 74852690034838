/* Menu CSS */
.menu_second {
    position: absolute;
    background-color: transparent;
    height: 12vh;
    align-items: center;
    z-index: 10;
    display: flex;
    width: 100%;
    justify-content: space-around;
    top: 0;
  }
  .menu_second.no-absolute {
    position: static;
    /* or another position value */
  }
  
  .menu_links {
    display: flex;
    justify-content: space-between;
    width: 50vw;
  }
  
.link{
    color: #bde9ffb8;
    font-size: 1.5vw;
}
.link:hover{
    cursor: pointer;
}

@media screen and (max-width: 750px) {
  .menu_second{
    display: none !important;
  }
}