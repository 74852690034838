.contact{
    color: white;
    padding-left: 50px;
    padding-bottom: 50px;
}

.contact h1{
    margin: 0;
}

.contact_inputs{
    display: flex;
    flex-direction: column;
}

.contact_inputs input{
    width: 50%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    height: 20px;
    font-size: 15px;
}

.menu_links_socials{
    color: white;
    display: flex;
    margin-top: 35px;
    justify-content: space-around;
    width: 50%;
  }
  .menu_links_socials img{
    width: 20px;
  }