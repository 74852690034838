.about_parents{
    background-color: rgb(2 20 18);
    color: #bde9ffb8;
}

/* .about_content{
    padding: 1%;
} */

.about_content_side{
    display: flex;
    justify-content: space-around;
}

.about_content{
    max-width: 100%;
    margin: 0 20px 0 2%;
}
.about_content p{
    font-size: 1.5vw;
}

@media screen and (max-width: 750px){
    .about_content{
        max-width: 100%;
        margin-right: 0px;
    }
    .about_content{
        padding: 5%;
    }
    .about_content h2{
        margin-left: 5px;
    }
    .about_content p{
        text-align: justify; 
    }
}