.projectsList{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 3% 1% 1% 1%;
    min-height: 50vh;
}


.project{
    width: 30vw;
    height: 45vh;
    margin-bottom: 20px;
    padding-top: 10px;
    border-radius: 50px;
    padding: 20px 10px;
}


.projectLink{
    position: relative;
    display: block;
    height: 100%;
    padding-top: 5px;
    color: white;
}

.project .projectLink{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transition: 0.5s;
}

.project .projectLink:hover{
    transform: translateY(-20px);
}

.project .projectLink::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,#13270248,#12549259);
}

.project .projectLink::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,#13270248,#12549259);
    filter: blur(30px);
}

.project .projectLink span{
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.project .projectLink span::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: rgba(255,255,255,0.1);
}

.project .projectLink .projectLinkContent{
    position: relative;
    padding: 15px 25px;
    z-index: 10;
}

.projectsList_button{
    display: flex;
    justify-content: center;
}
.projectsList_button a{ 
    padding: 15px;
    border-radius: 5px;
}
.projectsList_button a:hover{ 
    background: rgba(189, 233, 255, 0.72) !important;
    color: black;
    cursor: pointer;
}

.projectsTitle h2{
    text-decoration: none !important;
    margin: 0;
    font-size: 2vw;
}

.projectsTitle{
    width: 100%;
    color: white;
}
.projectsDescription{
    width: 100%;
    color: white;
}
.projectsDescription p{
    font-size: 1.2vw;
}
.projectLinkContent {
    text-decoration: none;
}

@media screen and (max-width:750px){
    .project{
        width: 100vw;
        height: 100vw;
    }
    .project .projectLink .projectLinkContent{
        padding: 20px 20px;
    }
    .projectsDescription{
        width: 100%;
    }
    .projectsTitle{
        width: 100%;
        
    }
}